/**
 * Check if currently within a Bootstrap breakpoint
 * @param {string} alias Bootstrap breakpoint ['xs', 'sm', 'md', 'lg', 'xl']
 * @return {boolean}
 */

function isBreakpoint(alias) {
  return $(".is-visible-" + alias).is(":visible");
}

/**
 * Window scoped vars for ajax archives.
 */
var totalReturned = 0,
  totalPages,
  totalPosts;

/**
 * Trigger Download on Page Load
 */
function thankYouDownloadTrigger() {
  $("document").ready(function () {
    setTimeout(function () {
      $("#thank-you-download")
        .click(function () {
          // 'this' is not a jQuery object, so it will use
          // the default click() function
          this.click();
        })
        .click();
    }, 2000);
  });
}
/**
 * Sticky Sidebar.
 * @link https://www.npmjs.com/package/sticky-js
 */
function addstickySidebar() {
  var sticky = new Sticky("#sticky");
}
/**
 * Update URL param function.
 * @link https://gist.github.com/excalq/2961415
 */
function updateQueryStringParam(key, value) {
  var baseUrl = [
      location.protocol,
      "//",
      location.host,
      location.pathname,
    ].join(""),
    urlQueryString = document.location.search,
    newParam = key + "=" + value,
    params = "?" + newParam;

  // If the "search" string exists, then build params from it
  if (urlQueryString) {
    var updateRegex = new RegExp("([?&])" + key + "[^&]*");
    var removeRegex = new RegExp("([?&])" + key + "=[^&;]+[&;]?");

    // Remove param if value is empty
    if (typeof value === "undefined" || value === null || value === "") {
      params = urlQueryString.replace(removeRegex, "$1");
      params = params.replace(/[&;]$/, "");

      // If param exists already, update it
    } else if (urlQueryString.match(updateRegex) !== null) {
      params = urlQueryString.replace(updateRegex, "$1" + newParam);

      // Otherwise, add it to end of query string
    } else {
      params = urlQueryString + "&" + newParam;
    }
  }

  // no parameter was set so we don't need the question mark
  params = params === "?" ? "" : params;

  window.history.replaceState({}, "", baseUrl + params);
}

/**
 * Update URL and remove all query string params
 */
function formOffset() {
  $(window).bind("load resize", function () {
    var form = $("#resourceForm, #eventForm");
    if (isBreakpoint("md") || isBreakpoint("lg") || isBreakpoint("xl")) {
      var height = $(".page-header").outerHeight();
      var adjustedHeight = 50 - height;

      form.css("margin-top", adjustedHeight + "px");
    } else {
      form.css("margin-top", "0px");
    }
    form.css("opacity", "1");
  });
}

/**
 * Update URL and remove all query string params.
 */
function removeAllUrlParams() {
  var baseUrl = [
    location.protocol,
    "//",
    location.host,
    location.pathname,
  ].join("");
  window.history.replaceState({}, "", baseUrl);
}

/**
 * Get posts via ajax and WP REST API.
 * @param {object} options
 */
function getAjaxPosts(args) {
  var $wrapper = $("#postsWrapper"),
    context = $wrapper.attr("data-context"),
    options = typeof args === "undefined" ? SAGE_ARCHIVE_VARS : args,
    url = SAGE_AJAX_URL + "wp-json/litmus/v1/" + context + "/?";

  // append options as params to url
  url += $.param(options);
  console.log(url);
  /*
   * Make ajax request to WP endpoint.
   * This is where most everything happens.
   */
  $.getJSON(url, function (data, status, xhr) {
    // get response headers for pagination
    totalPages = xhr.getResponseHeader("x-wp-totalpages");
    totalPosts = xhr.getResponseHeader("x-wp-total");

    // loop over each result and append to our wrapper
    $(data).each(function () {
      $wrapper.append(this.html);
      totalReturned++;
    });
  }).done(function () {
    // check if we should show load more button
    $("#loadMoreBtn")
      .attr("data-finished", totalReturned >= totalPosts)
      .prop("disabled", false)
      .text(function () {
        return $(this).data("text");
      });

    // turn off ajax loader
    $("#ajaxLoader").addClass("d-none");

    // if no posts are returned, add 'no posts found' message
    if (parseInt(totalPosts) === 0) {
      $wrapper.append(
        '<div class="col-12"><div class="alert alert-warning">No results found. Try resetting your filters.</div></div>'
      );
    }
  });
}

/**
 * Our archive ajax functionality wrapped
 * into a callable object with methods.
 */
var sageArchiveAjax = {
  init: function () {
    // vars
    this.$wrapper = $("#postsWrapper");
    this.$loader = $("#ajaxLoader");
    this.$loadMoreBtn = $("#loadMoreBtn");
    // run on init
    getAjaxPosts();
    this.initLoadMore();
    this.initFiltering();
    this.initSearch();
    this.initResetBtn();
  },
  clearPosts: function () {
    $("#ajaxLoader").removeClass("d-none");
    totalReturned = 0;
    SAGE_ARCHIVE_VARS.paged = 1;
    this.$wrapper.empty();
    this.$loadMoreBtn.text(function () {
      return $(this).data("text");
    });
  },
  initLoadMore: function () {
    this.$loadMoreBtn
      .text(function () {
        return $(this).data("text");
      })
      .on("click", function () {
        $(this).text($(this).data("loading-text")).prop("disabled", true);
        SAGE_ARCHIVE_VARS.paged++;
        getAjaxPosts();
      });
  },
  initFiltering: function () {
    var _this = this;
    $("[data-filter]").on("changed.bs.select", function (e) {
      var dataOptions,
        selected = $(e.target).selectpicker("val"),
        filterKey = $(this).data("filter");
      // the options to pass to ajax
      dataOptions = selected.join();
      if (selected.length === 0) {
        delete SAGE_ARCHIVE_VARS[filterKey];
      } else {
        SAGE_ARCHIVE_VARS[filterKey] = dataOptions;
      }
      // update URL query string
      updateQueryStringParam(filterKey, dataOptions);

      _this.clearPosts();
      getAjaxPosts();
    });
  },
  initSearch: function () {
    var _this = this;
    function doSearch() {
      var s = $("#jsSearch").val();
      SAGE_ARCHIVE_VARS.se = s;
      updateQueryStringParam("se", s);
      _this.clearPosts();
      getAjaxPosts();
    }

    // search event listeners
    $("#jsSearchSubmit").on("click", function () {
      doSearch();
    });
    $("#jsSearch").on("keypress", function (e) {
      if (e.keyCode === 13) {
        doSearch();
      }
    });
  },
  initResetBtn: function () {
    var _this = this;
    $("#resetFiltersBtn").on("click", function () {
      // reset stuff
      $(".selectpicker option:selected").prop("selected", false);
      $(".selectpicker").selectpicker("render");
      $("#jsSearch").val("");
      removeAllUrlParams();
      SAGE_ARCHIVE_VARS = {
        posts_per_page: SAGE_AJAX_DEFAULTS.posts_per_page,
        paged: SAGE_AJAX_DEFAULTS.paged,
      };

      // clear and load the posts
      _this.clearPosts();
      getAjaxPosts();
    });
  },
};

/**
 * Initialize sticky nav bar.
 *
 * @link https://www.sitepoint.com/animated-sticky-navigation-menu-javascript/
 */
function initStickyNav() {
  var refOffset = 0;
  var bannerHeight = 125;
  var bannerWrapper = document.querySelector(".sticky-nav");
  var banner = document.querySelector(".primary-nav");

  var handler = function () {
    var newOffset = window.scrollY || window.pageYOffset;

    if (newOffset > bannerHeight) {
      if (newOffset > refOffset) {
        bannerWrapper.classList.remove("animate-in");
        bannerWrapper.classList.add("animate-out");
      } else {
        bannerWrapper.classList.remove("animate-out");
        bannerWrapper.classList.add("animate-in");
      }
      bannerWrapper.classList.remove("top");
      refOffset = newOffset;
    } else {
      bannerWrapper.classList.add("top");
    }
  };

  window.addEventListener("scroll", handler, false);
}



/**
 * Smooth scroll for jump links.
 * @link https://css-tricks.com/snippets/jquery/smooth-scrolling/
 */
function smoothScrollLinks() {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .not("[data-toggle]")
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ===
          this.pathname.replace(/^\//, "") &&
        location.hostname === this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        if (target.length) {
          target = target;
        } else {
          $("[name=" + this.hash.slice(1) + "]");
        }
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top - 150,
            },
            500,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
}

function smoothScrollAccordion() {


  $('button[data-toggle*="collapse"]')
    // Remove links that don't actually link to anything
    .click(function (event) {
      // On-page links
      event.preventDefault();

        // Figure out element to scroll to
       var target = $('.answer-wrapper--phase-2');
        if ($("html, body").scrollTop() < (target.offset().top)){

        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          $("html, body").stop().animate(
            {
              scrollTop: target.offset().top,
            },
            500,
            function () {


              // Callback after animation
              // Must change focus!

              if (target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {

                target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                target.focus(); // Set focus again
              }
            }

          );
        }
        }
    });
}
/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
        $(".js-read-more").readmore({
          collapsedHeight: 150,
          embedCSS: false,
          speed: 300,
          lessLink:
            '<div class="more-toggle-wrapper"><a class="more-toggle mt-3 toggle-close" role="button" href="#">Show Fewer</a></div>',
          moreLink:
            '<div class="more-toggle-wrapper"><a class="more-toggle mt-3" role="button" href="#">Show All</a></div>',
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(document).ready(function () {
          $(".modal").each(function () {
            var src = $(this).find("iframe").attr("src");

            $(this).on("click", function () {
              $(this).find("iframe").attr("src", "");
              $(this).find("iframe").attr("src", src);
            });
          });
        });
        // Initialize sticky nav bar
        initStickyNav();
        smoothScrollLinks();
        // Main menu search
        var searchActiveClass = "header-search-active";
        function closeSearch() {
          $("#headerSearchForm").removeClass("active").find("input").blur();
          $("#headerSearchToggle").attr("aria-hidden", true);
          $(this).attr("aria-hidden", true);
          $("body").removeClass(searchActiveClass);
        }
        $("#headerSearchToggle").on("click", function (e) {
          e.stopPropagation();
          $("#headerSearchForm").addClass("active").find("input").focus();
          $(this).attr("aria-hidden", false);
          $("body").addClass(searchActiveClass);
        });
        $(".header-search-form-close-btn").on("click", function (e) {
          closeSearch();
        });
        $(document).on("click keypress", function (e) {
          if ($("body").hasClass(searchActiveClass)) {
            if (e.type === "click" || e.keyCode === 27) {
              closeSearch();
            }
          }
        });
      },
    },
    // Home page
    post_type_archive_story: {
      finalize: function () {
        sageArchiveAjax.init();
      },
    },
	post_type_archive_event: {
      finalize: function () {
        sageArchiveAjax.init();
      },
    },
    blog: {
      finalize: function () {
        sageArchiveAjax.init();
      },
    },
    page_template_template_thank_you: {
      finalize: function () {
        thankYouDownloadTrigger();
      },
    },
    news_all: {
      finalize: function () {
        sageArchiveAjax.init();
      },
    },
    press_all: {
      finalize: function () {
        sageArchiveAjax.init();
      },
    },
    page_template_default: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the if quote slider exists
        var quote_slider = $(".block-quote-slider .quotes-wrap").length;

        if (quote_slider > 0) {
          $(".block-quote-slider .quotes-wrap").owlCarousel({
            loop: true,
            margin: 10,
            nav: false,
            responsive: {
              0: {
                touchDrag: false,
                mouseDrag: false,
                items: 1,
              },
              768: {
                loop: true,
                autoplay: true,
                autoplayTimeout: 8000,
                autoplayHoverPause: true,
                items: 3,
              },
            },
          });
        }
		$('.basic-carousel').owlCarousel({
		autoplay:false,
		margin:60,
		nav:true,
		dots:false,
		navText : ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
		stagePadding: 25,
		responsive:{
			0:{
				items:1
			},
			768:{
				items:3,
			},
			992:{
				items:6,
			}
		}
	});  
	




		$('.basic-carousel-review').owlCarousel({
		autoplay:false,
		margin:66,
		nav:true,
		dots:false,
		navText : ['<i class="fa fa-chevron-left" aria-hidden="true"></i>','<i class="fa fa-chevron-right" aria-hidden="true"></i>'],
		stagePadding: 25,
		responsive:{
			0:{
				items:1
			},
			768:{
				items:2
			}
		}
	});  
       
        
		//Needed because of strtolower and ucwords
        $('.hint.hubspot .hint-text').text('HubSpot');

        var hash = location.hash.replace(/^#/, '');
        if (hash && $('.block-menu-accordions').length ) {
            var activeTab = $('a[href="#' + hash + '"]');
            if( activeTab.length ) {
                 activeTab.addClass('active').prop('aria-selected','true');
                 activeTab.siblings().removeClass('active').prop('aria-selected','false');
            }
            var activeTabContent = $('#' + hash);
            if( activeTabContent.length ) {
                 activeTabContent.addClass('show active');
                 activeTabContent.siblings().removeClass('show active');
			}
		}
	},
	},
    single_resource: {
      finalize: function () {
        formOffset();
      },
    },
    single_event: {
      finalize: function () {
        formOffset();
      },
    },
    single_post: {
      finalize: function () {
        addstickySidebar();
      },
    },
    page_template_template_no_header_landing: {
      finalize: function () {
        $('.gall').owlCarousel({
          loop:true,
          items:1,
          nav:true,
          autoplay:true,
          autoplayTimeout:5000,
          autoplayHoverPause:true,
        });
      },
    },
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
        i,
        classnm
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, "finalize");
      });
      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };
	// Load Events
	$(document).ready(UTIL.loadEvents);
})(jQuery); 
// Fully reference jQuery after this point.